.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body .CodeMirror-hints {
  border-radius: 2px;
  border-color: rgba(0, 0, 0, 0.12);
}
.CodeMirror-hints .CodeMirror-hint {
  cursor: pointer;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.CodeMirror-hints .CodeMirror-hint:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.CodeMirror-hints .cm-category {
  color: rgba(0, 0, 0, 0.54);
}
.CodeMirror-hints li.CodeMirror-hint-active,
.CodeMirror-hints li.CodeMirror-hint-active:hover {
  background: #FFD256;
}
.CodeMirror-hints li.CodeMirror-hint-active .hint-value {
  color: #222630;
}

